import { registerIconsBundle } from '@teliads/icons'
import React from 'react'
import AppContext from './src/app-context'

export const onClientEntry = () => {
  registerIconsBundle()
}

// Update One trust on every route change
export const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')
  if (otConsentSdk) {
    otConsentSdk.remove()
  }

  if (window.OneTrust != null) {
    window.OneTrust.Init()

    setTimeout(function () {
      window.OneTrust.LoadBanner()

      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings'
      )

      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }
    }, 1000)
  }
}

export const onRouteUpdate = () => {
  reloadOTBanner()
}

export const wrapRootElement = ({ element }) => {
  <AppContext.Provider>
    {element}
  </AppContext.Provider>
}
