import { createContext } from 'react'
import { UAParser } from 'ua-parser-js'

const userAgentParser = new UAParser()

const appState = {
  // There are actually several device types detected (console, mobile, tablet, smarttv, wearable, embedded). We just treat all of them as "mobile".
  // Undefined usually means it's a "pc".
  isMobileDevice: userAgentParser.getDevice().type !== undefined
}

const AppContext = createContext(appState)

export default AppContext
