import '../../node_modules/normalize.css/normalize.css'
import '@teliads/components/dist/telia-component-library/telia-component-library.css'
import '@teliads/components/dist/collection/customProperties/multiplier10px.css'
// The Gaia component library modifies html and body font-size so we need some multiplier styles from voca to keep things sized properly.
import '@gaiads/telia-react-component-library/build/index.css'
import React from 'react'
import { TeliaVocaStats } from '@teliads/components/react'
import TeliaNavigation from '@gaiads/telia-react-cms-services'
import { AntiFlicker } from '@teliads/components/antiflicker/react'
import './layout.scss'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { scrollToFragment } from '../utils/scroll-to-fragment'

// https://www.gatsbyjs.com/docs/how-to/routing/layout-components/
// TODO: Could the layout plugin be replaced with gatsby-browser.js wrapPageElement: https://dev.to/crock/gatsby-js-browser-apis-explained-wrappageelement-vs-wraprootelement-n4k
export default function Layout ({ children }) {
  !isServerSideRendering() && scrollToFragment()

  return (
    <>
      <Helmet>
        {getMarketingScripts()}
      </Helmet>

      {process.env.NODE_ENV === 'development' && (
        <TeliaVocaStats project='cmsdotsite-fi' country='FI' />
      )}

      <TeliaNavigation marketSegment='B2C' />

      {/* TODO: Has Voca AntiFlicker been fixed to ignore VST? */}
      <AntiFlicker style={{ display: 'none' }} regex='/(TELIA-|B2(X|C|B)-)(?!VST)/'>
        {children}
      </AntiFlicker>
    </>
  )
}

const getMarketingScripts = () => {
  if (isServerSideRendering()) {
    return marketingConsentDeniedScripts
  }

  const marketingConsentApproved = isMarketingConsentApproved()

  if (marketingConsentApproved) {
    return marketingConsentApprovedScripts
  }

  return marketingConsentDeniedScripts
}

const isServerSideRendering = () => {
  return typeof window === 'undefined'
}

/*
  CookieInformationConsent cookie is a JSON value, e.g.:
  {
    "website_uuid": "2f116e80-94d9-4b3c-b1fc-109fe6722798",
    "timestamp": "2021-03-22T05:54:00.378Z",
    "consent_url": "https://www.preprod.telia.fi/test/CMSW-620-Navigation-b2c-react",
    "consent_website": "preprod.telia.fi",
    "consent_domain": "www.preprod.telia.fi",
    "user_uid": "7c3da237-a269-4063-92b1-4cbc3d153053",
    "consents_approved": [
      "cookie_cat_necessary",
      "cookie_cat_functional",
      "cookie_cat_statistic",
      "cookie_cat_marketing",
      "cookie_cat_unclassified"
    ],
    "consents_denied": [],
    "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.90 Safari/537.36"
  }

  To test this by manually adding a encoded cookie trhough the developer tools:
  %7B%22website_uuid%22%3A%228a701cc3-9e93-41e1-ab0a-1f496481cb5a%22%2C%22timestamp%22%3A%222022-01-10T08%3A27%3A40.424Z%22%2C%22consent_url%22%3A%22https%3A%2F%2Fwww.telia.fi%2Fkauppahaku%22%2C%22consent_website%22%3A%22Telia.fi%22%2C%22consent_domain%22%3A%22www.telia.fi%22%2C%22user_uid%22%3A%22ec48c8b1-f322-480a-a51e-82e8f5016b9d%22%2C%22consents_approved%22%3A%5B%22cookie_cat_necessary%22%2C%22cookie_cat_functional%22%2C%22cookie_cat_statistic%22%2C%22cookie_cat_marketing%22%2C%22cookie_cat_unclassified%22%5D%2C%22consents_denied%22%3A%5B%5D%2C%22user_agent%22%3A%22Mozilla%2F5.0%20%28Macintosh%3B%20Intel%20Mac%20OS%20X%2010.15%3B%20rv%3A96.0%29%20Gecko%2F20100101%20Firefox%2F96.0%22%7D
*/
const isMarketingConsentApproved = () => {
  const cookieConsentValue = Cookies.get('CookieInformationConsent')

  if (cookieConsentValue) {
    const consentJsonString = decodeURIComponent(cookieConsentValue)
    let consent = {}

    try {
      consent = JSON.parse(consentJsonString)
    } catch (error) {
      console.log('Failed to parse CookieInformationConsent cookie value:', consentJsonString)
      return false
    }

    if (consent && consent.consents_approved && consent.consents_approved.includes('cookie_cat_marketing')) {
      return true
    }
  }

  return false
}

const marketingConsentDeniedScripts = (
  [
    <script id='google-optimize-script-no-marketing-consent' key='google-optimize-script-no-marketing-consent' async src='' data-consent-src='https://www.googleoptimize.com/optimize.js?id=GTM-TFLS56W' data-category-consent='cookie_cat_marketing' />,
    <script id='teliafi-blueconic-script-no-marketing-consent' key='teliafi-blueconic-script-no-marketing-consent' async src='' data-consent-src='https://telia-fi.blueconic.net/frontend/static/javascript/blueconic/blueconic.min.js' data-category-consent='cookie_cat_marketing' />
  ]
)

const marketingConsentApprovedScripts = (
  [
    <script id='google-optimize-script' key='google-optimize-script' src='https://www.googleoptimize.com/optimize.js?id=GTM-TFLS56W' />,
    <script id='teliafi-blueconic-script' key='teliafi-blueconic-script' async src='https://telia-fi.blueconic.net/frontend/static/javascript/blueconic/blueconic.min.js' />
  ]
)
